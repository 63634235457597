import React, { useEffect } from "react"
import LandingPage from "../../../components/PageComponents/2-minute-quiz-landing-page"

const PrematureEjaculation = () => {
  useEffect(() => {
    renderBingScript()
  }, [])

  const renderBingScript = () => {
    const script = document.createElement("script")
    script.type = "text/javascript"

    let code = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"211030219", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`

    script.appendChild(document.createTextNode(code))
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }

  return <LandingPage prefix="ca" treatment="pe" />
}

export default PrematureEjaculation
